import React from "react";
import styled from "@emotion/styled";

import mediaqueries from "@styles/media";

import { Icon } from '@types';

const Logo: Icon = ({ fill = "white" }) => {
  return (
    <LogoContainer>
      <svg
        width="35"
        height="35"
        viewBox="0 0 35 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="Logo__Desktop"
      >
        <g clipPath="url(#clip0)">
            fill={fill}
          />
          <path
            d="M28.008 4.969H7.023c-1.011 0-1.832.82-1.832 1.828v21.437c0 1.008.82 1.828 1.832 1.828h20.985c1.012 0 1.832-.82 1.832-1.828V6.797c0-1.008-.82-1.828-1.832-1.828zm-10.723 4.91H11.7v3.156h4.809v2.625h-4.809v5.774H8.172V7.262h9.113zM31.441 0H3.59A3.29 3.29 0 00.3 3.29v28.452a3.29 3.29 0 003.29 3.29H31.44a3.293 3.293 0 003.293-3.29V3.29A3.295 3.295 0 0031.441 0zm-.304 28.234a3.133 3.133 0 01-3.13 3.13H7.024a3.133 3.133 0 01-3.128-3.13V6.797a3.133 3.133 0 013.128-3.129h20.985a3.133 3.133 0 013.129 3.129zm0 0"
            fill={fill}
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="191.156" height="35" fill="white" />
          </clipPath>
        </defs>
      </svg>

      <svg
        width="35"
        height="35"
        viewBox="0 0 35 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="Logo__Mobile"
      >
        <path d="M28.008 4.969H7.023c-1.011 0-1.832.82-1.832 1.828v21.437c0 1.008.82 1.828 1.832 1.828h20.985c1.012 0 1.832-.82 1.832-1.828V6.797c0-1.008-.82-1.828-1.832-1.828zm-10.723 4.91H11.7v3.156h4.809v2.625h-4.809v5.774H8.172V7.262h9.113zM31.441 0H3.59A3.29 3.29 0 00.3 3.29v28.452a3.29 3.29 0 003.29 3.29H31.44a3.293 3.293 0 003.293-3.29V3.29A3.295 3.295 0 0031.441 0zm-.304 28.234a3.133 3.133 0 01-3.13 3.13H7.024a3.133 3.133 0 01-3.128-3.13V6.797a3.133 3.133 0 013.128-3.129h20.985a3.133 3.133 0 013.129 3.129zm0 0" fill={fill} />
        />
      </svg>
    </LogoContainer>
  );
};

export default Logo;

const LogoContainer = styled.div`
  .Logo__Mobile {
    display: none;
  }

  ${mediaqueries.tablet`
    .Logo__Desktop {
      display: none;
    }
    
    .Logo__Mobile{
      display: block;
    }
  `}
`;
